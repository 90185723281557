import React from 'react';
import {Text, Card, TextPriority} from 'wix-ui-tpa/cssVars';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {classes} from './PickupInfoBox.st.css';
import {PickupInfoModel} from '../../../domain/models/PickupInfo.model';
import {cleanAddress} from '../../../domain/utils/cleanAddress';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {SPECS} from '../constants';

export enum PickupInfoBoxDataHook {
  root = 'PickupInfoBox.root',
  addressTitle = 'PickupInfoBox.addressTitle',
  addressDetails = 'PickupInfoBox.addressDetails',
  deliveryTimeDetails = 'PickupInfoBox.deliveryTimeDetails',
  instructionsTitle = 'PickupInfoBox.instructionsTitle',
  instructionsDetails = 'PickupInfoBox.instructionsDetails',
}

export interface PickupInfoBoxProps {
  pickupInfo?: PickupInfoModel;
  insideTimeSelector?: boolean;
}

const PickupInfoBoxInternal = ({pickupInfo, insideTimeSelector}: PickupInfoBoxProps) => {
  const localeKeys = useLocaleKeys();
  const {experiments} = useExperiments();

  // eslint-disable-next-line no-nested-ternary
  return experiments.enabled(SPECS.ShowTimeSlotsUIChanges) ? (
    pickupInfo?.instructions ? (
      <div className={insideTimeSelector ? classes.insideTimeSelector : classes.root}>
        <Card data-hook={PickupInfoBoxDataHook.root}>
          <Card.Container>
            <Text
              tagName={'h4'}
              data-hook={PickupInfoBoxDataHook.instructionsTitle}
              priority={TextPriority.primary}
              className={classes.title}>
              {localeKeys.checkout.pickup_details_form.store_pickup_instructions_header()}
            </Text>
            <Text
              data-hook={PickupInfoBoxDataHook.instructionsDetails}
              priority={TextPriority.secondary}
              className={classes.instructions}>
              {pickupInfo.instructions}
            </Text>
          </Card.Container>
        </Card>
      </div>
    ) : null
  ) : (
    <div>
      <Card data-hook={PickupInfoBoxDataHook.root} className={classes.root}>
        {!!pickupInfo?.address && (
          <Card.Container>
            <Text
              tagName={'h4'}
              data-hook={PickupInfoBoxDataHook.addressTitle}
              priority={TextPriority.primary}
              className={classes.title}>
              {localeKeys.checkout.pickup_details_form.store_pickup_address_header()}
            </Text>
            <Text data-hook={PickupInfoBoxDataHook.addressDetails} priority={TextPriority.secondary}>
              {cleanAddress(
                localeKeys.checkout.delivery_method.pickup_address({
                  addressLine: pickupInfo?.address?.addressLine,
                  city: pickupInfo?.address?.city,
                  country: pickupInfo?.address?.countryFullname,
                  subdivision: pickupInfo?.address?.subdivisionFullname,
                  zipCode: pickupInfo?.address?.postalCode,
                })
              )}
            </Text>
          </Card.Container>
        )}
        {!!pickupInfo?.deliveryTime && (
          <Card.Container>
            <Text
              data-hook={PickupInfoBoxDataHook.deliveryTimeDetails}
              priority={TextPriority.secondary}
              className={classes.deliveryTime}>
              {pickupInfo.deliveryTime}
            </Text>
          </Card.Container>
        )}
        {!!pickupInfo?.instructions && (
          <Card.Container>
            <Text
              tagName={'h4'}
              data-hook={PickupInfoBoxDataHook.instructionsTitle}
              priority={TextPriority.primary}
              className={classes.title}>
              {localeKeys.checkout.pickup_details_form.store_pickup_instructions_header()}
            </Text>
            <Text
              data-hook={PickupInfoBoxDataHook.instructionsDetails}
              priority={TextPriority.secondary}
              className={classes.instructions}>
              {pickupInfo.instructions}
            </Text>
          </Card.Container>
        )}
      </Card>
    </div>
  );
};

export const PickupInfoBox = React.memo(PickupInfoBoxInternal);
