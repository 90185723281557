import React, {ReactNode} from 'react';
import {RadioButton, RadioButtonTheme} from 'wix-ui-tpa/cssVars';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {DeliveryMethodPickerDataHook} from './DeliveryMethodPicker';
import {classes} from './DeliveryMethodPicker.st.css';
import {ShippingOptionModel, TimeSlotOption} from '../../../../domain/models/ShippingOption.model';

export interface DeliveryMethodProps {
  option: ShippingOptionModel;
  onChange?: () => void;
  selectedId?: string;
  subtext?: string;
  disabled?: boolean;
  selectedDetailSection?: () => ReactNode;
  selectedShippingOptionId?: string;
}

export const DeliveryMethod = ({
  option,
  onChange,
  selectedId,
  subtext,
  selectedDetailSection,
  disabled,
  selectedShippingOptionId,
}: DeliveryMethodProps) => {
  const isChecked = selectedId === option.code;
  const localeKeys = useLocaleKeys();
  const isFree = option.price?.amount === 0;
  let foundSelectedTimeSlot: TimeSlotOption | null = null;
  let isFreeFoundSelectedTimeSlot = false;
  if (option.hasTimeSlots && selectedShippingOptionId) {
    foundSelectedTimeSlot = findTimeSlotOptionById(option.timeSlotDays!, selectedShippingOptionId);
    if (foundSelectedTimeSlot) {
      isFreeFoundSelectedTimeSlot = foundSelectedTimeSlot.price.amount === 0;
    }
  }

  const getPriceLabel = () => {
    if (foundSelectedTimeSlot) {
      return isFreeFoundSelectedTimeSlot
        ? localeKeys.checkout.delivery_method.free_label()
        : foundSelectedTimeSlot.price.formattedAmount;
    }

    return isFree ? localeKeys.checkout.delivery_method.free_label() : option.price.formattedAmount;
  };

  return (
    <>
      <RadioButton
        data-hook={DeliveryMethodPickerDataHook.shippingOption}
        className={classes.radioButton}
        value={option.code}
        checked={isChecked}
        label={option.title}
        subtext={subtext}
        disabled={disabled}
        suffix={
          <span data-hook={DeliveryMethodPickerDataHook.shippingOptionPrice} className={classes.priceTag}>
            {getPriceLabel()}
          </span>
        }
        onChange={onChange}
        theme={RadioButtonTheme.Box}
      />
      {isChecked && selectedDetailSection?.()}
    </>
  );
};

function findTimeSlotOptionById(timeSlotDays: {[date: string]: TimeSlotOption[]}, id: string): TimeSlotOption | null {
  for (const date in timeSlotDays) {
    /* istanbul ignore else */
    if (Object.prototype.hasOwnProperty.call(timeSlotDays, date)) {
      const timeSlotOptions = timeSlotDays[date];
      const foundOption = timeSlotOptions.find((option) => option.id === id);
      if (foundOption) {
        return foundOption;
      }
    }
  }
  return null;
}
